<template>
  <vs-popup
    title="ข้อมูลติดต่อสอบถาม"
    :active="$attrs.value"
    class-content="popup-example"
    class="contact-us-modal"
    @close="onClose"
  >
    <FormLayout>
      <FormLabel>
        <span>ชื่อ: </span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-8/12 pt-2">
        <span class="break-word">{{data.name}}</span>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>Email: </span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-8/12 pt-2">
        <span class="break-word">{{data.email}}</span>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>เบอร์โทรศัพท์: </span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-8/12 pt-2">
        <span class="break-word">{{data.telephone}}</span>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>ข้อความ: </span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-8/12 pt-2">
        <p class="break-word">{{data.message}}</p>
      </FormContent>
    </FormLayout>
    <FormLayout>
      <FormLabel>
        <span>วันที่สร้างข้อมูล: </span>
      </FormLabel>
      <FormContent customClass="vx-col w-full md:w-8/12 pt-2">
        <span class="break-word">{{createdAt}}</span>
      </FormContent>
    </FormLayout>
  </vs-popup>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import useDatepicker from '@/use/useDatepicker'

export default {
  name: 'PopupLanguage',
  components: {
    Datepicker,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    createdAt () {
      const { updatedAtDatetimeFormat } = useDatepicker()
      return updatedAtDatetimeFormat(this.data.created_at)
    }
  },
  methods: {
    onClose(value) {
      this.$emit('input', value)
    }
  },
}
</script>

<style lang="css">
  @media (min-width: 768px){
    .contact-us .vs-popup {
      width: 70%;
    }
  }
  @media (max-width: 767px){
    .contact-us .vs-popup {
      width: 100%;
    }
  }
  .disable-area {
    color: darkgray !important;
    border-color: #dcdcdc !important;
  }
</style>